<template lang="pug">
.relative
  .vx-navbar-wrapper
    vs-navbar.vx-navbar.navbar-custom(
      :color='navbarColor'
      :class='classObj'
    )
      feather-icon(
        class="sm:inline-flex xl:hidden lg:hidden cursor-pointer mr-1" 
        icon="MenuIcon"
        @click.stop="showSidebar"
      )
      vs-spacer
      change-language
      ConfigMenu
      menu-login
</template>

<script>
import MenuLogin from "@/layouts/components/navbar/MenuLogin"
import SearchBar from "@/layouts/components/navbar/SearchBar"
import ConfigMenu from "@/layouts/components/navbar/ConfigMenu"
import Notifications from "@/layouts/components/navbar/Notifications"
import ChangeLanguage from "@/components/lang/ChangeLanguage"

export default {
  name: "the-navbar",
  components: {
    MenuLogin,
    SearchBar,
    ConfigMenu,
    ChangeLanguage,
    "vue-notification": Notifications
  },
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    }
  },
  data() {
    return {
      searchQuery: "",
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: "Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)",
          category: "primary"
        },

        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: "Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)",
          category: "success"
        },

        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: "Thu Jan 31 2019 07:45:23 GMT+0000 (GMT)",
          category: "danger"
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: "Fri Feb 01 2019 07:45:23 GMT+0000 (GMT)",
          category: "primary"
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: "Fri Feb 02 2019 07:45:23 GMT+0000 (GMT)",
          category: "warning"
        }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    }
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    }
  },
  computed: {
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    breakpoint() {
      return this.$store.state.breakpoint
    },
    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == "default") return "navbar-default"
      else if (this.sidebarWidth == "reduced") return "navbar-reduced"
      else if (this.sidebarWidth) return "navbar-full"
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true)
    },
    actionClicked(item) {
      // e.stopPropogation()
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction
      })
    },
    outside: function() {
      this.showBookmarkPagesDropdown = false
    }
  }
}
</script>
