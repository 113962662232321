<template lang="pug">
  div(
    class="layout--main"
    :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]"
  )
    vx-tour(
      :steps="steps"
      v-if="!disableThemeTour"
    )
    the-customizer(
      v-if="!disableCustomizer"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :footerType="footerType"
      :routerTransition="routerTransition"
      :hideScrollToTop="hideScrollToTop"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateFooter="updateFooter"
      @updateRouterTransition="updateRouterTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
    )
    vx-sidebar(
      :sidebarItems='sidebarItems'
      :logo="require('@/assets/images/logo/logo.png')"
      :logo_letras="require('@/assets/images/logo/logo_letras.png')"
      parent='.layout--main'
    )
    #content-area(
      :class="[contentAreaClass, {'show-overlay': bodyOverlay}]"
    )
      #content-overlay
      .content-wrapper
        the-navbar(
          :navbarColor="navbarColor"
          :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]"
        )
        .router-view
          .router-content(
            :class="{'mt-0': navbarType == 'hidden'}"
          )
            transition(
              :name="routerTransition"
            )
              .router-header.flex.flex-wrap.items-center.mb-6(
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              )
                .content-area__heading(
                  :class="{'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light' : $route.meta.breadcrumb}"
                )
                  h2.mb-1 {{ $t(routeTitle) }}
                vx-breadcrumb.ml-4.hidden(
                  class="md:block"
                  v-if="$route.meta.breadcrumb"
                )
                  vs-dropdown.ml-auto.hidden.cursor-pointer(
                    class="md:block"
                    vs-trigger-click
                  )
                    vs-button(
                      radius
                      icon="icon-settings"
                      icon-pack="feather"
                    )
                    vs-dropdown-menu.w-32
                      vs-dropdown-item
                        router-link.flex.items-center
                          feather-icon.inline-block.mr-2(
                            icon="UserIcon"
                            svgClasses="w-4 h-4"
                          )
                            span Profile
                      vs-dropdown-item
                        router-link.flex.items-center
                          feather-icon.inline-block.mr-2(
                            icon="CheckSquareIcon"
                            svgClasses="w-4 h-4"
                          )
                            span Tasks
                      vs-dropdown-item
                        router-link.flex.items-center
                          feather-icon.inline-block.mr-2(
                            icon="MailIcon"
                            svgClasses="w-4 h-4"
                          )
                            span Inbox
            .content-area__content
              transition(
                :name="routerTransition"
                mode="out-in"
              )
                router-view(
                  @changeroutetitle="changeRouteTitle"
                )
      the-footer
</template>

<script>

import VxSidebar from "@/layouts/components/vx-sidebar/VxSidebar.vue"
import TheNavbar from "../components/TheNavbar.vue"
import TheFooter from "../components/TheFooter.vue"
import TheCustomizer from "../components/customizer/TheCustomizer.vue"
import themeConfig from "@/../themeConfig.js"
import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js"
import JwtService from "@/jwt-service"

const VxTour = () => import('@/components/VxTour.vue')

export default {
  data () {
    return {
      navbarType: themeConfig.navbarType || "floating",
      navbarColor: themeConfig.navbarColor || "#fff",
      footerType: themeConfig.footerType || "static",
      routerTransition: themeConfig.routerTransition || "none",
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      sidebarItems: sidebarItems,
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      windowWidth: window.innerWidth, //width of windows
      steps: [
        {
            target: '#btnSidebarToggler',
            content: 'Toggle Collapse Sidebar.'
        },
        {
            target: '.vx-navbar__starred-pages',
            content: 'Create your own bookmarks. You can also re-arrange them using drag & drop.'
        },
        {
            target: '.i18n-locale',
            content: 'You can change language from here.'
        },
        {
            target: '.navbar-fuzzy-search',
            content: 'Try fuzzy search to visit pages in flash.'
        },
        {
            target: '.customizer-btn',
            content: 'Customize template based your preference',
            params: {
                placement: 'left'
            }
        },
      ]
    }
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      if (this.navbarColor == "#fff" && val) {
        this.updateNavbarColor("#10163a")
      } else {
        this.updateNavbarColor("#fff")
      }
    }
  },
  computed: {
    isAppPage() {
      if (this.$route.path.includes("/apps/")) return true
      else return false
    },
    isThemeDark() {
      return this.$store.state.theme == "dark"
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.sidebarWidth == "default") return "content-area-default"
      else if (this.sidebarWidth == "reduced") return "content-area-reduced"
      else if (this.sidebarWidth) return "content-area-full"
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating"
      }
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static"
      }
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val == "#fff") this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter(val) {
      this.footerType = val
    },
    updateRouterTransition(val) {
      this.routerTransition = val
    },
    updateNavbar(val) {
      if(val == 'static') this.updateNavbarColor("#fff")
      this.navbarType = val
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.setSidebarWidth()
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)
        this.$store.dispatch("updateSidebarWidth", "no-sidebar")
      } else if (this.windowWidth < 1200) {
        this.$store.dispatch("updateSidebarWidth", "reduced")
      } else {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true)
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val
    }
  },
  components: {
    VxSidebar,
    TheNavbar,
    TheFooter,
    VxTour,
    TheCustomizer
  },
  created () {
    this.$store.commit('auth/SET_USER_PROFILE', JSON.parse(localStorage.getItem('userInfo')))
    this.$store.commit('auth/SET_USER_ROLES', JSON.parse(localStorage.getItem('rolInfo')))
    this.$store.commit('auth/SET_USER_PERMISSION', JSON.parse(localStorage.getItem('permission')))
    setTimeout(() => {
      this.$store.commit('auth/SET_USER_TOKEN', JwtService.getToken())
    }, 500)
    this.setSidebarWidth()
    if (this.navbarColor == "#fff" && this.isThemeDark) {
      this.updateNavbarColor("#10163a")
    } else {
      this.updateNavbarColor(this.navbarColor)
    }
  },
}
</script>
