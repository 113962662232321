<template lang="pug">
div
  .search-full-container.w-full.h-full.absolute.pin-l.rounded-lg(
    :class="{'flex': showFullSearch}" 
    v-show="showFullSearch"
  )
    vx-auto-suggest.w-full(
      :autoFocus="showFullSearch" 
      :data="navbarSearchAndPinList"
      @selected="selected" 
      ref="navbarSearch" 
      @closeSearchbar="showSearchBar" 
      placeholder="Search.."
      inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus no-icon-border" 
      icon="SearchIcon" 
      background-overlay
    )
    .absolute.pin-r.h-full.z-50
      feather-icon.px-4.cursor-pointer.h-full.close-search-icon(
        icon="XIcon" 
        @click="showFullSearch = false"
      )
  feather-icon.cursor-pointer.navbar-fuzzy-search.ml-4(
    icon="SearchIcon" 
    @click="showSearchBar"
  )
</template>

<script>
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue"

//Constantes
let COMPONENT_NAME = "SearchBar"

export default {
  name: COMPONENT_NAME,
  components: {
    VxAutoSuggest
  },
  data() {
    return {
      showFullSearch: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList
    }
  },
  computed: {
    data() {
      return this.$store.state.navbarSearchAndPinList
    }
  },
  methods: {
    selected(item) {
      this.$router.push(item.url)
      this.showFullSearch = false
    },
    showSearchBar () {
      return this.showFullSearch = ! this.showFullSearch
    },
  }
}
</script>
