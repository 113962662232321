<template lang="pug">
  div(
    class="the-navbar__user-meta flex items-center sm:ml-5 ml-2"
  )
    vs-dropdown(
      vs-custom-content
      class="cursor-pointer"
    )
      feather-icon.cursor-pointer.navbar-fuzzy-search.ml-4(
        icon='SettingsIcon'
      )
      vs-dropdown-menu
        ul(style="min-width: 13rem")
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/roles')"
            v-show="$can('read', 'roles')"
          )
            feather-icon(
              icon="UnlockIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.perfiles') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/status')"
            v-show="$can('read', 'status')"
          )
            feather-icon(
              icon="BookmarkIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.estados') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/companies')"
            v-show="$can('read', 'company')"
          )
            feather-icon(
              icon="GlobeIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.company') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/user')"
            v-show="$can('read', 'users')"
          )
            feather-icon(
              icon="UsersIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.usuarios') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/news')"
            v-show="$can('read', 'company')"
          )
            feather-icon(
              icon="FilePlusIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t("manage_news.manage_news") }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/create/client')"
            v-show="$can('read', 'users')"
          )
            feather-icon(
              icon="UsersIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.users_create') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/demo_signup/prod')"
            v-show="$can('read', 'users')"
          )
            feather-icon(
              icon="UsersIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('company_externo') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/sms')"
            v-show="$can('read', 'roles')"
          )
            feather-icon(
              icon="MessageSquareIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.sms') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/custom-emails')"
            v-show="$can('read', 'email')"
          )
            feather-icon(
              icon="MailIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.emails') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/activate-whatsapp')"
            v-show="$can('read', 'whatsapp')"
          )
            feather-icon(
              icon="SmartphoneIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.whatsapp') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/services')"
            v-show="$can('read', 'services')"
          )
            feather-icon(
              icon="FileMinusIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.servicios') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/webhooks')"
            v-show="$can('read', 'webhook')"
          )
            feather-icon(
              icon="CloudIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('configuracion.webhooks') }}
</template>
<script>

import { UserPlusIcon } from "vue-feather-icons"

export default {
  components: {
    UserPlusIcon
  },
  methods: {
    groupsPush () {
      this.$router.push({ name: 'groupsCedi' })
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$swal({
          position: 'center',
          type: 'success',
          title: this.$t('bye-plataform'),
          confirmButtonColor: '#FDD402',
          cancelButtonColor: "#ea5455",
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 1500,
          }).then((result) => {
            if (result) {
              this.$router.push({ name: "Login" })
            }
          })
      })
    }
  }

}
</script>
