<template lang="pug">
.vs-sidebar--item(
  :class="[{'vs-sidebar-item-active':activeLink}, {'disabled-item pointer-events-none': isDisabled}]"
)
  router-link(
    v-if='to' 
    :to='to' 
    :class="[{'router-link-active': activeLink}]" 
    :target='target' exact=''
  )
    vs-icon(
      v-if='!featherIcon' 
      :icon-pack='iconPack' 
      :icon='icon'
    )
    feather-icon(
      :icon='icon' 
      :class="{'w-3 h-3': iconSmall}" 
      v-if="packIcon"
    )
    img(
      :src="svgIcon"
      class="w-10 h-10 mr-2 img-svg"
      v-if="svgIcon"
    )
    slot
  a(
    v-else='' 
    :target='target' 
    :href='href'
  )
    vs-icon(
      v-if='!featherIcon' 
      :icon-pack='iconPack' 
      :icon='icon'
    )
    feather-icon(
      :icon='icon' 
      :class="{'w-3 h-3': iconSmall}" 
      v-else=''
    )
    slot
</template>

<script>
export default {
  name: "VxSidebarItem",
  props: {
    icon: {
      default: "",
      type: String
    },
    iconSmall: {
      default: false,
      type: Boolean
    },
    iconPack: {
      default: "material-icons",
      type: String
    },
    packIcon: {
      default: true,
      type: Boolean
    },
    href: {
      default: "#",
      type: String
    },
    to: {
      default: null,
      type: String
    },
    index: {
      default: null,
      type: [String, Number]
    },
    featherIcon: {
      default: true,
      type: Boolean
    },
    svgIcon: {
      type: String
    },
    target: {
      default: "_self",
      type: String
    },
    isDisabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      activeLink: false
    }
  },
  watch: {
    $route() {
      this.CheckIsActive()
    }
  },
  methods: {
    CheckIsActive() {
      if (this.to) {
        if (
          this.$route.path.slice(1).includes(this.to.slice(1)) &&
          this.to.slice(1)
        )
          this.activeLink = true
        else this.activeLink = false
      }
    }
  },
  updated() {
    this.CheckIsActive()
  }
}
</script>

<style>
.main-menu-sidebar .vs-sidebar--item .router-link-active .img-svg {
    filter: brightness(3);
}
</style>
