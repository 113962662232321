<template lang="pug">
footer.the-footer.flex-wrap.justify-between(:class='classes')
  span
    | COPYRIGHT @ {{ new Date().getFullYear() }} 
    a(href='https://quick.com.co/' target='_blank' rel='nofollow') Quick
    | , {{ $t('footer.derechos') }}
  //- span.hidden.items-center(class='md:flex')
  //-   span Hand-crafted &amp; Made with
  //-   feather-icon.ml-2(icon='HeartIcon' svgclasses='stroke-current text-danger w-6 h-6')
</template>

<script>

export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    }
}
</script>
