/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    iconPack: false,
    svgIcon: "22.svg",
    i18n: "Dashboard"
  },
  {
    url: "/services",
    name: "Status",
    slug: "status",
    icon: "FileMinusIcon",
    iconPack: true,
    i18n: "configuracion.servicios",
  },
  {
    url: "/status",
    name: "Status",
    slug: "status",
    icon: "ActivityIcon",
    iconPack: true,
    i18n: "configuracion.estados",
  },
  {
    url: null,
    name: "companies",
    slug: "companies",
    iconPack: true,
    icon: "GlobeIcon",
    i18n: "companies",
    submenu: [
      {
        url: "/companies",
        name: "User",
        i18n: "gestionar_company",
        isDisabled: true
      },
      {
        url: "/demo_signup/prod",
        name: "User",
        i18n: "company_externo",
      },
      {
        url: "/sms",
        name: "User",
        i18n: "configuracion.sms",
      },
      {
        url: "/custom-emails",
        name: "User",
        i18n: "configuracion.emails",
      },
      {
        url: "/activate-whatsapp",
        name: "User",
        i18n: "configuracion.whatsapp",
      },
      {
        url: "/webhooks",
        name: "User",
        i18n: "configuracion.webhooks",
      },
      {
        url: "/activate-help",
        name: "User",
        i18n: "help_companies.title",
      },
    ]
  },
  {
    url: null,
    name: "User",
    slug: "User",
    iconPack: true,
    icon: "UserIcon",
    i18n: "usuarios",
    submenu: [
      {
        url: "/pages/user",
        name: "User",
        slug: "user",
        icon: "UserIcon",
        iconPack: true,
        i18n: "gestionar_usuarios",
        isDisabled: true
      },
      {
        url: "/create/client",
        name: "User",
        slug: "task",
        icon: "UserIcon",
        iconPack: true,
        i18n: "users_create",
      },
      {
        url: "/roles",
        name: "User",
        slug: "roles",
        icon: "UsersIcon",
        iconPack: true,
        i18n: "configuracion.perfiles"
      },
    ]
  }
]
